import React, { useEffect, useMemo } from 'react';
import {
  AudioIcon,
  // AutoRotateIcon,
  ContactFormIcon,
  // ExpandIcon,
  // FloorMapIcon,
  MapLineIcon,
  // FullScreenIcon,
  // HelpIcon,
  MapIcon,
  AutoPlayIcon,
  // ShareIcon,
} from 'components/icons';
import configs from 'configs';
// import { isMobile } from 'react-device-detect';

// import NotePlate from './NotePlate';

import './SchoolLayoutIcons.scss';
// import {
//   AquaAutoRotateIcon,
//   AquaFullscreenIcon,
//   AquaMapfloorplanIconV1,
//   PrimeMapFloorPlanIcon,
//   ShareIcon,
// } from 'components/icons/AquaIcons';
// import AquaExtraIcons from './AquaExtraIcons';
// import { isMobile } from 'react-device-detect';
import HoveringPlate from 'components/HoveringPlate';
import { HomeIcon } from 'components/icons/HomeIcon';
import MinorSector from 'components/MapViewAngle/MinorSector';
import { useDispatch, useSelector } from 'react-redux';
import { setHeadingIndicatorShow } from 'store/actions';

export default function PrimeLayoutIcons(props) {
  const {
    currentPano,
    color,
    // toggleHelpButton,
    featuredMedia,
    isShowAudioIcon,
    // canFullscreen,
    // menuPosition,
    // handleFullScreen,
    isShowMapFloorPlan,
    setIsActiveMap,
    isActiveMap,
    mapShowed,
    // setOpenDropDown,
    // openDropDown,
    // handleSelectMap,
    scenes,
    // floorMaps,
    switchMode,
    handleUpdateRoute,
    onSelect,
    isPrimeStyle,
    isShowOnMap,
    shouldShowMapIcon,
    isAutoPlay,
    // fullScreen,
    setIsOpenMapfloorplanModal,
  } = props;

  // console.log(props);

  // const handleGoHome = () => {
  //   window.location.href = 'https://khaihoanprime.vn/';
  // };

  const { headingIndicatorShow } = useSelector((state) => state);
  const dispatch = useDispatch();

  useEffect(() => {
    dispatch(setHeadingIndicatorShow(false));
    setTimeout(() => {
      dispatch(setHeadingIndicatorShow(true));
    }, 500);
    // eslint-disable-next-line
  }, [currentPano]);

  const isIndoorMap = useMemo(() => {
    return !isNaN(currentPano?.id);
  }, [currentPano]);

  // const isMobile = useMemo(() => window.innerWidth <= 767, []);

  const getViewPointTitle = (title) => {
    if (isNaN(title)) {
      return title;
    }
    return '';
  };

  const hideHoverTitleScenes = [
    'can-ho-1pn-scene-1',
    'can-ho-1pn-scene-2',
    'can-ho-1pn-scene-3',
    'can-ho-1pn-scene-4',
    'can-ho-1pn-scene-5',
    'can-ho-1pn-scene-6',
    '6656adae6f19211ee871ef2e',
    '6656adb56f19211ee871ef38',
    '6656adbe6f19211ee871ef43',
    '6656adc76f19211ee871ef4d',
    '6656add46f19211ee871ef74',
    '6656adda6f19211ee871ef7f',
    '6656ade5b892461864518f52',
    '6656adecb892461864518f5b',
    '6656adf36f19211ee871ef8f',
    '6656adfa6f19211ee871ef98',
    '66596788016c0b19c4446631',
  ];

  return (
    <div
      className={`icons_right ${isPrimeStyle ? 'icons_right-container' : ''}`}
    >
      {isShowMapFloorPlan && (
        <>
          {/* <HoveringPlate
            textHover={'Mặt bằng tổng thể'}
            isPrimeStyle={isPrimeStyle}
          > */}
          <div
            className={`icon position-relative ${
              isPrimeStyle ? 'icon-primeStyle' : ''
            } 
              `}
            onClick={() => {
              setIsActiveMap(!isActiveMap);
              setIsOpenMapfloorplanModal && setIsOpenMapfloorplanModal();
            }}
          >
            {/* <MapFloorPlanIcon active={isActiveMap} /> */}
            <MapLineIcon active={isActiveMap} activeColor={color} />
          </div>
          {/* </HoveringPlate> */}

          <div
            className={`mapfloorplan_container ${
              isPrimeStyle ? 'mapfloorplan_container-primeStyle' : ''
            } ${isActiveMap ? 'open-aacorp' : ''} ${
              isIndoorMap ? 'indoor-map' : ''
            }`}
          >
            {mapShowed && (
              <>
                <div className="map_image">
                  <div
                    className="map_close_btn"
                    onClick={() => setIsActiveMap(false)}
                  >
                    <i className="icon-close"></i>
                  </div>
                  <img src={mapShowed.image.url} alt={mapShowed.image.name} />
                  {mapShowed.objects.map((viewpoint, index) => {
                    const currentPoint = viewpoint.id === currentPano._id;
                    const [left, top] = viewpoint.coordinates;
                    const leftInPercent = left * 100;
                    const topInPercent = top * 100;
                    const currentScene =
                      scenes.find((s) => s._id === viewpoint.id) || {};

                    const isHide = hideHoverTitleScenes.includes(
                      currentScene._id
                    );

                    return (
                      <div key={index}>
                        <div
                          className={`viewpoint-container  ${
                            currentPoint
                              ? 'current-viewpoint aquaAnimation primeColor'
                              : `viewpoint ${
                                  isPrimeStyle ? 'aquaViewPoint primeColor' : ''
                                }`
                          } `}
                          style={{
                            left: `${leftInPercent}%`,
                            top: `${topInPercent}%`,
                          }}
                          onClick={() => {
                            dispatch(setHeadingIndicatorShow(false));
                            if (
                              currentScene.id.indexOf(
                                'tong-the-toan-canh-02'
                              ) !== -1
                            ) {
                              onSelect(
                                currentScene.groupId,
                                currentScene.id,
                                true,
                                true
                              );
                            } else {
                              onSelect(currentScene.groupId, currentScene.id);
                            }
                          }}
                        >
                          {!isHide && (
                            <HoveringPlate
                              textHover={getViewPointTitle(currentScene.title)}
                            >
                              <div className="hover-point"></div>
                            </HoveringPlate>
                          )}
                        </div>
                        <div
                          className={`circular-sector ${
                            headingIndicatorShow ? 'show' : ''
                          }`}
                          style={{
                            position: 'absolute',
                            display: `${currentPoint ? 'block' : 'none'}`,
                            left: `${leftInPercent}%`,
                            top: `${topInPercent}%`,
                            transform: 'translate(-50%, -50%)',
                            zIndex: -1,
                          }}
                        >
                          <MinorSector {...props} />
                        </div>
                      </div>
                    );
                  })}
                </div>
              </>
            )}
          </div>
        </>
      )}

      {/* {isPrimeStyle && <AquaExtraIcons />} */}

      {shouldShowMapIcon && isPrimeStyle && (
        <div
          className={`icon ${isPrimeStyle ? 'icon-primeStyle' : ''}`}
          style={{
            display: isShowOnMap ? 'flex' : 'none',
          }}
          onClick={() => handleUpdateRoute(configs.mapRoute)}
        >
          <MapIcon />
        </div>
      )}

      {isShowAudioIcon && (
        <AudioIcon
          activeColor={color}
          className={`icon ${isPrimeStyle ? 'icon-primeStyle' : ''}`}
        />
      )}

      {
        <div
          className={`icon ${isPrimeStyle ? 'icon-primeStyle' : ''}`}
          onClick={() => switchMode()}
        >
          <AutoPlayIcon shouldRotate={isAutoPlay} activeColor={color} />
        </div>
      }

      {/* {canFullscreen() && menuPosition !== 'bottom' && (
        <div
          className={`icon ${isPrimeStyle ? 'icon-primeStyle' : ''}`}
          onClick={handleFullScreen}
        >
          <FullScreenIcon fullScreen={fullScreen} activeColor={color} />
        </div>
      )} */}

      <div
        className={`icon ${isPrimeStyle ? 'icon-primeStyle home' : ''}`}
        onClick={() => onSelect('welcome', 'tong-the-toan-canh-01')}
      >
        <HomeIcon />
      </div>

      {/* 
      <HoveringPlate textHover={'Hướng dẫn'}>
        <div
          className={`icon ${isPrimeStyle ? 'icon-aquaStyle' : ''}`}
          onClick={toggleHelpButton}
        >
          <HelpIcon />
        </div>
      </HoveringPlate> */}

      {featuredMedia.map && (
        <div
          className={`icon ${isPrimeStyle ? 'icon-primeStyle' : ''}`}
          onClick={() => handleUpdateRoute(featuredMedia.map.id)}
        >
          <MapLineIcon active={isActiveMap} activeColor={color} />
        </div>
      )}

      {featuredMedia.contact && (
        <div
          className={`icon ${isPrimeStyle ? 'icon-primeStyle' : ''}`}
          onClick={() => handleUpdateRoute(featuredMedia.contact.id)}
        >
          <ContactFormIcon />
        </div>
      )}

      {/* <HoveringPlate textHover={'Chia sẻ'}>
        <div
          className={`icon ${isPrimeStyle ? 'icon-aquaStyle' : ''}`}
          onClick={handleShare}
        >
          <ShareIcon />
        </div>
      </HoveringPlate> */}

      {/* <div
        className={`icon ${isPrimeStyle ? 'icon-aquaStyle' : ''}`}
        onClick={() => {
          toggleRotate();
        }}
      >
        <AutoRotateIcon shouldRotate={isAutoPlay} activeColor={color} />
      </div> */}
    </div>
  );
}
